import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';
import { globalState } from 'shared/foreground/models';
import { setKindleExportFrequency } from 'shared/foreground/stateUpdaters/clientStateUpdaters/other';

import ChevronDown from '../icons/20StrokeChevronDownSmall';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './KindleExportFrequencyDropdown.module.css';

export default function KindleExportFrequencyDropdown({
  triggerClassName = '',
}: { triggerClassName?: string; }) {
  const [isOpen, setIsOpen] = useState(false);
  const exportFrequency = globalState(
    useCallback((state) => state.persistent.integrations.kindle?.exportFrequency, []),
  );

  const options = useMemo(
    () => [
      {
        type: DropdownOptionType.Item,
        name: 'weekly',
        checked: exportFrequency === 'weekly',
        onSelect: () => {
          setKindleExportFrequency({ exportFrequency: 'weekly', userInteraction: 'click' });
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'daily',
        checked: exportFrequency === 'daily',
        onSelect: () => {
          setKindleExportFrequency({ exportFrequency: 'daily', userInteraction: 'click' });
        },
      },
    ],
    [exportFrequency],
  );

  return (
    <Dropdown
      appendToDocumentBody
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      contentAlignment="end"
      contentClassName={styles.dropdownContent}
      trigger={
        <DropdownMenu.Trigger asChild>
          <div className={`${triggerClassName} ${styles.dropdownTrigger}`}>
            {exportFrequency ?? 'weekly'}
            <ChevronDown />
          </div>
        </DropdownMenu.Trigger>
      }
    />
  );
}
